import defaults from "defaults";
import configUrl from "./config.json";
var config = {
    swaggerUrls: {
        _default: {
            host: 'https://api-int.sorted.com',
            path: '',
            swaggerPath: '/swagger/v1/swagger.json',
            headers: {},
            authType: 'sortedjwt'
        },
        'CustomerFiles': {
            host: 'https://sorted-customerfiles-feature.sorted.com'
        },
        'Quotes': {
            host: 'https://sorted-quotes-feature.sorted.com'
        },
        'UserManagement': {
            path: '/pro-ui/users'
        },
        'AutoManifesting': {
            path: '/pro-ui/config/auto_manifest'
        },
        'CarrierSubscriptions': {
            path: '/pro-ui/carrier_subscription'
        },
        'CarrierActivityShutters': {
            path: '/pro-ui/carrieractivityshutters'
        },
        'PostMidnightAvailability': {
            path: '/pro-ui/postmidnightavailability'
        },
        'Shipments': {
            path: '/pro-ui',
            swaggerPath: '/shipments_index/pro/swagger/v1.1/swagger.yaml',
            headers: {
                'x-api-version': '1.1'
            }
        }
    }
};
export default fetch(configUrl + "?v=".concat(Date.now()))
    .then(function (resp) { return resp.json(); })
    .then(function (configJson) {
    return window.swaggerConfig = defaults(configJson, config);
});
