var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import defaults from "defaults";
import fetchingConfig from "./config";
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
var SwaggerUIStandalonePreset = require("swagger-ui/dist/swagger-ui-standalone-preset");
export default (function (domNode) { return __awaiter(void 0, void 0, void 0, function () {
    function SpecInterceptor() {
        return {
            statePlugins: {
                spec: {
                    wrapActions: {
                        updateSpec: function (oriAction) { return function (str) {
                            if (!currSwaggerDoc)
                                return oriAction(str);
                            var spec = JSON.parse(str);
                            if (currSwaggerDoc) {
                                spec.host = currSwaggerDoc.host;
                                spec.basePath = currSwaggerDoc.path;
                                spec.servers = [{ url: "".concat(currSwaggerDoc.host).concat(currSwaggerDoc.path) }];
                            }
                            return oriAction(JSON.stringify(spec));
                        }; }
                    }
                }
            }
        };
    }
    var config, swaggerDocs, currSwaggerDoc;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchingConfig];
            case 1:
                config = _a.sent();
                swaggerDocs = getSwaggerDocs(config);
                currSwaggerDoc = false;
                SwaggerUI({
                    urls: __spreadArray([], __read(swaggerDocs), false).map(function (_a) {
                        var _b = __read(_a, 2), name = _b[0], url = _b[1].url;
                        return ({ name: name, url: url });
                    }),
                    presets: [
                        SwaggerUI.presets.apis,
                        SwaggerUIStandalonePreset
                    ],
                    plugins: [
                        SwaggerUI.plugins.DownloadUrl,
                        SpecInterceptor
                    ],
                    layout: 'StandaloneLayout',
                    validatorUrl: null,
                    tryItOutEnabled: true,
                    domNode: domNode,
                    requestInterceptor: function (req) {
                        var e_1, _a, e_2, _b;
                        try {
                            for (var swaggerDocs_1 = __values(swaggerDocs), swaggerDocs_1_1 = swaggerDocs_1.next(); !swaggerDocs_1_1.done; swaggerDocs_1_1 = swaggerDocs_1.next()) {
                                var _c = __read(swaggerDocs_1_1.value, 2), _ = _c[0], v = _c[1];
                                if (req.url.startsWith(v.url)) {
                                    if (v.authType == 'sortedjwt') {
                                        var token_1 = window.sessionStorage.getItem('auth.accessToken');
                                        if (token_1) {
                                            req.headers.Authorization = "Bearer ".concat(token_1);
                                        }
                                    }
                                    try {
                                        for (var _d = (e_2 = void 0, __values(Object.getOwnPropertyNames(v.headers))), _e = _d.next(); !_e.done; _e = _d.next()) {
                                            var headerName = _e.value;
                                            req.headers[headerName] = v.headers[headerName];
                                        }
                                    }
                                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                    finally {
                                        try {
                                            if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                                        }
                                        finally { if (e_2) throw e_2.error; }
                                    }
                                    return req;
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (swaggerDocs_1_1 && !swaggerDocs_1_1.done && (_a = swaggerDocs_1.return)) _a.call(swaggerDocs_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        var token = window.sessionStorage.getItem('auth.accessToken');
                        if (token) {
                            req.headers.Authorization = "Bearer ".concat(token);
                        }
                        return req;
                    },
                    responseInterceptor: function (resp) {
                        var e_3, _a;
                        try {
                            for (var swaggerDocs_2 = __values(swaggerDocs), swaggerDocs_2_1 = swaggerDocs_2.next(); !swaggerDocs_2_1.done; swaggerDocs_2_1 = swaggerDocs_2.next()) {
                                var _b = __read(swaggerDocs_2_1.value, 2), _ = _b[0], v = _b[1];
                                if (!resp.url.startsWith(v.url))
                                    continue;
                                currSwaggerDoc = v;
                                break;
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (swaggerDocs_2_1 && !swaggerDocs_2_1.done && (_a = swaggerDocs_2.return)) _a.call(swaggerDocs_2);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        return resp;
                    },
                });
                ;
                return [2 /*return*/];
        }
    });
}); });
function getSwaggerDocs(config) {
    var fillDefaults = function (entry) {
        return defaults(entry, config.swaggerUrls._default);
    };
    var formUrl = function (entry) { return (__assign({ url: "".concat(entry.host).concat(entry.path).concat(entry.swaggerPath) }, entry)); };
    return new Map(Object.getOwnPropertyNames(config.swaggerUrls)
        .filter(function (k) { return !k.startsWith('_'); })
        .map(function (k) { return [k, formUrl(fillDefaults(config.swaggerUrls[k]))]; }));
}
